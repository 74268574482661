import React, { useState } from 'react';
import { Navbar as ReactNavbar, NavbarBrand } from 'reactstrap';

const Navbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <ReactNavbar expand="md">
        <NavbarBrand className="mx-auto" href="/">
          <h1>
            Urgence Dentaire <span className="covid">COVID-19</span>{' '}
            <span className="badge badge-light">beta</span>
          </h1>
        </NavbarBrand>
      </ReactNavbar>
      <p className="subtitle">
        <b>IMPORTANT:</b> Dans le cadre du second confinement débutant le 30 Octobre 2020,<br /> les cabinets dentaires
        resteront a priori ouverts jusqu'à nouvel ordre. Cet outil d'aiguillage a pour vocation de vous orienter vers le bon interlocuteur, en cas de doute consultez votre dentiste ou votre médecin.
        <div className="last-update text-muted">dernière mise-à-jour: 29/10/2020</div>
      </p>
    </div>
  );
};

export default Navbar;
